import React, { useContext, useRef, useEffect } from 'react';
import { DBContext } from './DBContext';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import './RestTab.css';

function RestTab({ showPopup }) {
    const { swaggerSpec, swaggerOptions, selectedProfile } = useContext(DBContext);
    const isLoading = !swaggerSpec || !swaggerOptions;

    return (
        <>
            <div className="rest-tab">
            {showPopup && <div >
            <div >

                <h2>How to Use the Swagger API</h2>                

                <div className="guide-step">
                    <h3>1. Open the API Request Interface</h3>
                    <img src="/OpenIWURequest.png" alt="Open API Request Interface" />
                    <p>After selecting your desired schema, open the GET request header for a table you would like to request.</p>
                </div>
                
                <div className="guide-step">
                    <h3>2. Try Out the API</h3>
                    <img src="/IWUTryItOut.png" alt="Try Out API" />
                    <p>Click on the 'Try it out' button to begin testing.</p>
                </div>
                
                <div className="guide-step">
                    <h3>3. Selecting a Profile</h3>
                    <img src="/IWUSelectMFH.png" alt="Select Profile" />
                    <p>Select the appropriate profile for the data you wish to query. For example, use 'eq.MFH' to filter data to only include Gebäudetyp equals "MFH". All available operators for PostgREST can be found in the official <a href="https://postgrest.org/en/v12/references/api/tables_views.html">PostgREST documentation</a> .</p>
                </div>
                
                <div className="guide-step">
                    <h3>4. Set Limits and Execute</h3>
                    <img src="/IWULimitAndExecute.png" alt="Limit and Execute" />
                    <p>Always set a 'limit' parameter (e.g., limit=5) when testing to ensure the application remains responsive, then run the query using the execute button.</p>
                </div>
                
                <div className="guide-step">
                    <h3>5. Review the Request and Response</h3>
                    <img src="/IWURequestURL.png" alt="Review Request URL" />
                    <p>Examine the request URL generated by your inputs to ensure it meets your requirements or to copy it into whatever export client you prefer (Python requests, Javascript fetch).</p>
                </div>
                
                <div className="guide-step">
                    <h3>6. Check the API Response</h3>
                    <img src="/IWUResponse.png" alt="API Response" />
                    <p>Review the response returned by the API to validate the output against your expectations.</p>
                </div>
            </div>
        </div>}
                {isLoading && <div className="loading-container">Loading...</div>}
                {!isLoading && swaggerSpec && (
                    <Swagger
                        swaggerSpec={swaggerSpec}
                        selectedProfile={selectedProfile}
                    />
                )}
            </div>
        </>
    );
}

function Swagger({ swaggerSpec, selectedProfile }) {
    const profileRef = useRef(selectedProfile);

    useEffect(() => {
        profileRef.current = selectedProfile; 
    }, [selectedProfile]);

    const getSwaggerConfig = () => ({
        ...swaggerSpec,
        requestInterceptor: (request) => {
            request.headers['Accept-Profile'] = profileRef.current;
            request.headers['Content-Profile'] = profileRef.current;
            return request;
        }
    });

    const config = getSwaggerConfig();

    return (
        <>
            <SwaggerUI spec={swaggerSpec} {...config} />
        </>
    );
}

export default RestTab;
