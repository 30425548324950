import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
const consoleError = console.error;
console.error = (...args) => {
  const filteredWarnings = [
    /UNSAFE_componentWillMount/,
    /UNSAFE_componentWillReceiveProps/,
    /Support for defaultProps will be removed from function components/
  ];
  
  if (filteredWarnings.some(pattern => pattern.test(args[0]))) {
    return;
  }
  
  consoleError(...args);
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
